import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';
import CSSFiles from 'src/content/overview/components/CSSFiles';

const HomeScreenLayout = () => {
  useEffect(() => {
    const preloader = document.querySelector('#preloader');
    if (preloader) {
      setTimeout(() => {
        preloader.remove();
      }, 300);
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>OPD Next</title>
      </Helmet>
      <CSSFiles />
      <Outlet />
    </>
  );
};

export default HomeScreenLayout;
