import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as userActions from '../../../store/actions/user';
import Loading from 'src/components/Loading';
import { useNavigate } from 'react-router';
import { authContext } from 'src/Provider/AuthProvider';

const Protected = ({ children }) => {
  const userDetails = useSelector((state) => state.user.userDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setAuthData } = useContext(authContext);

  useEffect(() => {
    if (userDetails?.token || sessionStorage.getItem('TOKEN') !== null) {
      dispatch({
        type: 'SESSION_STORAGE_TOKEN',
        payload: userDetails?.token || sessionStorage.getItem('TOKEN')
      });
      if (!userDetails?.user && !userDetails?.user?.profile?.userName) {
        dispatch(
          userActions.fetchUserDetails(
            sessionStorage.getItem('TOKEN'),
            () => {
              setAuthData({
                userName: userDetails?.user.profile.userName,
                roles: userDetails?.user.account.authorities,
                token: userDetails?.token
              });
            },
            () => {
              navigate('/overview', { replace: true });
            }
          )
        );
      }
    } else {
      navigate('/overview', { replace: true });
    }
  }, []);

  if (!userDetails || !userDetails.user) {
    return <Loading />;
  }

  return children;
};
export default Protected;
