import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
// import FullscreenLayout from 'src/layouts/FullscreenLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import FullscreenLayoutWithoutFooter from './layouts/FullscreenLayoutWithoutFooter';
import HomeScreenLayout from './layouts/HomeScreenLayout';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Overview = Loader(lazy(() => import('src/content/overview')));
const ActivateUser = Loader(
  lazy(() => import('src/content/pages/screens/ActivateUser'))
);
const AboutUS = Loader(lazy(() => import('src/content/pages/screens/AboutUs')));
const AllFaqs = Loader(lazy(() => import('src/content/pages/screens/AllFaqs')));
const ForgotPassword = Loader(
  lazy(() => import('src/content/pages/screens/ForgotPassword'))
);
const ChangePassword = Loader(
  lazy(() => import('src/content/pages/screens/ChangePassword'))
);

// Policies
const PrivacyPolicy = Loader(
  lazy(() => import('src/content/pages/Policies/PrivacyPolicy'))
);
const CookiePolicy = Loader(
  lazy(() => import('src/content/pages/Policies/CookiePolicy'))
);
const SecurityPolicy = Loader(
  lazy(() => import('src/content/pages/Policies/SercurityPolicy'))
);
const TermsOfService = Loader(
  lazy(() => import('src/content/pages/Policies/TermsOfService'))
);

// Dashboards
const DoctorBoard = Loader(
  lazy(() => import('src/content/dashboards/DoctorBoard'))
);

const QueueBoard = Loader(
  lazy(() => import('src/content/dashboards/ScheduledQueue'))
);

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Patients

const PatientDetails = Loader(
  lazy(() => import('src/content/pages/Components/PatientDetails'))
);

// Components
const ManageCalendars = Loader(
  lazy(() => import('src/content/pages/Components/ManageCalendars'))
);

const ManageCalendarFulllscreen = Loader(
  lazy(() => import('src/content/pages/Components/ManageCalendarFullscreen'))
);

const GenerateCalendar = Loader(
  lazy(() => import('src/content/pages/Components/GenerateCalendar'))
);

const Users = Loader(lazy(() => import('src/content/pages/Components/Users')));
const User = Loader(lazy(() => import('src/content/pages/Components/User')));
const UpdateProfile = Loader(
  lazy(() => import('src/content/applications/Users/settings/UpdateProfile'))
);
const Branches = Loader(
  lazy(() => import('src/content/pages/Components/Branches'))
);
const Branch = Loader(
  lazy(() => import('src/content/pages/Components/Branch'))
);

const Departments = Loader(
  lazy(() => import('src/content/pages/Components/Departments'))
);
const Department = Loader(
  lazy(() => import('src/content/pages/Components/Department'))
);
const Patients = Loader(
  lazy(() => import('src/content/pages/Components/Patients'))
);
const Patient = Loader(
  lazy(() => import('src/content/pages/Components/Patient'))
);

const Pharmacy = Loader(
  lazy(() => import('src/content/pages/Components/Pharmacy'))
);

// authenticate
const Login = Loader(lazy(() => import('src/content/pages/screens/Login')));
const Registeration = Loader(
  lazy(() => import('src/content/pages/screens/Registration'))
);

const routes = [
  {
    path: '',
    element: <HomeScreenLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'overview',
        element: <Overview />
      },
      {
        path: 'aboutus',
        element: <AboutUS />
      },
      {
        path: 'allfaqs',
        element: <AllFaqs />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'register',
        element: <Registeration />
      },
      {
        path: 'forgotpassword',
        element: <ForgotPassword />
      },
      {
        path: 'privacy',
        element: <PrivacyPolicy />
      },
      {
        path: 'cookie',
        element: <CookiePolicy />
      },
      {
        path: 'security',
        element: <SecurityPolicy />
      },
      {
        path: 'termsofservice',
        element: <TermsOfService />
      },
      {
        path: 'activate',
        element: <ActivateUser />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="tasks" replace />
      },
      {
        path: 'userhome',
        element: <Crypto />
      }
    ]
  },
  {
    path: 'doctor',
    element: <FullscreenLayoutWithoutFooter />,
    children: [
      {
        path: '',
        element: <Navigate to="board" replace />
      },
      {
        path: 'board',
        element: <DoctorBoard />
      }
    ]
  },
  {
    path: 'queue',
    element: <FullscreenLayoutWithoutFooter />,
    children: [
      {
        path: '',
        element: <Navigate to="board" replace />
      },
      {
        path: 'board',
        element: <QueueBoard />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          },
          {
            path: 'editprofile',
            element: <UpdateProfile />
          },
          {
            path: 'changepassword',
            element: <ChangePassword />
          }
        ]
      }
    ]
  },
  {
    path: 'patient/:id',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="profile" replace />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <PatientDetails />
          }
        ]
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: 'managecalendars',
        element: <ManageCalendars />
      },
      {
        path: 'generatecalendar',
        element: <GenerateCalendar />
      },
      {
        path: 'users',
        element: <Users />
      },
      {
        path: 'user',
        element: <User />
      },
      {
        path: 'branches',
        element: <Branches />
      },
      {
        path: 'branch',
        element: <Branch />
      },
      {
        path: 'departments',
        element: <Departments />
      },
      {
        path: 'department',
        element: <Department />
      },
      {
        path: 'patients',
        element: <Patients />
      },
      {
        path: 'patient',
        element: <Patient />
      },
      {
        path: 'pharmacy',
        element: <Pharmacy />
      }
    ]
  },
  {
    path: '/components',
    element: <FullscreenLayoutWithoutFooter />,
    children: [
      {
        path: 'managecalendarsfullscreen',
        element: <ManageCalendarFulllscreen />
      }
    ]
  }
];

export default routes;
