import { createDataWithToken } from 'src/helpers/db';
import { httpErrorResponse, LOADING_START, LOADING_STOP } from './app';

export const SET_SELECTED_DEPARTMENT = 'SET_SELECTED_DEPARTMENT';
export const FETCH_COMPANY_DEPARTMENTS = 'FETCH_COMPANY_DEPARTMENTS';
export const FETCH_ALL_MATCHING_USERS = 'FETCH_ALL_MATCHING_USERS';
export const ADD_NEW_DEPARTMENT = 'ADD_NEW_DEPARTMENT';

export const fetchCompanyDepartments = (callback) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const requestData = {};
    dispatch({ type: LOADING_START });
    createDataWithToken(
      `departments/v1/department/find?page=${0}&size=${10}&sort=ASC`,
      userDetails.token,
      requestData
    )
      .then((response) => {
        dispatch({
          type: FETCH_COMPANY_DEPARTMENTS,
          payload: response
        });
        callback();
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
    callback();
  };
};

export const setSelectedDept = (selectedDept) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_DEPARTMENT,
      payload: selectedDept
    });
  };
};

export const createNewDepartment = (formData, callback) => {
  return (dispatch, getState) => {
    console.log('formdata department: ', formData);
    const userDetails = getState().user.userDetails;
    const requestData = {
      payload: {
        ...formData
      }
    };
    dispatch({ type: LOADING_START });
    createDataWithToken(
      'departments/v1/department',
      userDetails.token,
      requestData
    )
      .then((response) => {
        dispatch({
          type: ADD_NEW_DEPARTMENT,
          payload: response
        });
        callback();
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const readAllUsersForBranch = (firstName) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const requestData = {
      pageNo: 0,
      pageSize: 5,
      sortBy: 'firstName',
      sortDirection: 'ASC',
      filters: {
        firstName: firstName
      }
    };

    dispatch({ type: LOADING_START });
    createDataWithToken(
      'users/v1/user/find/matching',
      userDetails.token,
      requestData
    )
      .then((response) => {
        if (response.data.length > 0) {
          dispatch({
            type: FETCH_ALL_MATCHING_USERS,
            payload: response.data
          });
        }
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const readUsersById = (userId, callback) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const requestData = {
      pageNo: 0,
      pageSize: 5,
      sortBy: 'firstName',
      sortDirection: 'ASC',
      filters: {
        userId: userId
      }
    };

    dispatch({ type: LOADING_START });
    createDataWithToken('users/v1/user/find', userDetails.token, requestData)
      .then((response) => {
        if (response.data.length > 0) {
          dispatch({
            type: FETCH_ALL_MATCHING_USERS,
            payload: response.data
          });
        }
        dispatch({ type: LOADING_STOP });
        callback(response.data[0]);
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};
