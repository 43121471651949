import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import { CookiesProvider } from 'react-cookie';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { Provider } from 'react-redux';
import configureStore from './store/Store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthProvider from './Provider/AuthProvider';
import { useEffect } from 'react';
// initializing aos
import AOS from 'aos';
import 'aos/dist/aos.css';
// initializing glightbox
// import GLightbox from 'glightbox';
// initializing Swiper
// import Swiper from 'swiper';
// import 'swiper/css';

const store = configureStore();

function App() {
  const routerOutlet = useRoutes(router);
  useEffect(function () {
    /**
     * Initiate AOS
     */
    AOS.init();
    /**
     * Initiate glightbox
     */
    // GLightbox({
    //   selector: '.glightbox'
    // });

    if (window.location.hash) {
      if (document.querySelector(window.location.hash)) {
        setTimeout(() => {
          let section = document.querySelector(window.location.hash);
          let scrollMarginTop = getComputedStyle(section).scrollMarginTop;
          window.scrollTo({
            top: section.offsetTop - parseInt(scrollMarginTop),
            behavior: 'smooth'
          });
        }, 100);
      }
    }
  }, []);
  return (
    <ThemeProvider>
      <Provider store={store}>
        <CookiesProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AuthProvider>
              <CssBaseline />
              {routerOutlet}
              <ToastContainer limit={3} autoClose={5000} />
            </AuthProvider>
          </LocalizationProvider>
        </CookiesProvider>
      </Provider>
    </ThemeProvider>
  );
}
export default App;
