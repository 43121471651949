export default {
  BOOKED: {
    name: 'Booked',
    value: 'BOOKED',
    color: 'success'
  },
  CANCELLED: {
    name: 'Cancelled',
    value: 'CANCELLED',
    color: 'error'
  },
  OPEN: {
    name: 'Open',
    value: 'OPEN',
    color: 'primary'
  }
};
