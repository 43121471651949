import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as companyActions from '../../../../store/actions/company';

const BranchSelector = ({ sx }) => {
  const [options, setOptions] = React.useState([]);
  const companyState = useSelector((state) => state.company.companyDetails);
  const workspaceBranch = useSelector((state) => state.company.workspaceBranch);
  const [branch, setBranch] = React.useState(workspaceBranch || null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyState && companyState.branches && companyState.branches.length) {
      const branchesOptions = companyState.branches.map((branch) => {
        return {
          value: branch.branchId,
          label: branch.branchName,
          ...branch
        };
      });
      setOptions(branchesOptions);
    }
  }, [companyState]);

  const handleChange = (event) => {
    setBranch(event.target.value);
    dispatch(
      companyActions.updateWorkspaceBranch(event.target.value, () => {})
    );
  };

  return (
    <FormControl
      sx={{ m: 1, minWidth: 170, maxWidth: 170, ...sx }}
      size="small"
    >
      <InputLabel id="test-select-label">Branch</InputLabel>
      <Select
        labelId="test-select-label"
        label="Branch"
        id="branchId"
        value={branch}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              maxWidth: 150,
              minWidth: 150,
              overflowY: 'auto',
              scrollbarWidth: 'thin'
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BranchSelector;
