import {
  createData,
  createDataWithToken,
  readDataWithToken
} from 'src/helpers/db';
import { LOADING_START, LOADING_STOP, httpErrorResponse } from './app';
import { showSuccessToaster } from 'src/helpers/toaster';

export const BRANCH_SELECTED = 'BRANCH_SELECTED';
export const FETCH_COMPANY_DETAILS = 'FETCH_COMPANY_DETAILS';
export const UPDATE_WORKSPACE_BRANCH = 'UPDATE_WORKSPACE_BRANCH';

/**
 * Fetch all branches of specified company Id
 * @param {*} companyId
 * @returns
 */
export const fetchCompanyDetails = (companyId) => {
  return (dispatch, getState) => {
    const workspaceBranch = getState().company.workspaceBranch;
    dispatch({ type: LOADING_START });
    readDataWithToken(
      `company/v1/find/${companyId}`,
      getState().user.userDetails.token
    )
      .then((response) => {
        dispatch({
          type: FETCH_COMPANY_DETAILS,
          payload: response.data,
          branchDetails: response.data.branches.find(
            (item) => item.branchId === workspaceBranch
          )
        });
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

/**
 * This endpoint is used while updating branch form manage branches page
 * @param {*} formData
 * @param {*} successCallback
 * @returns
 */
export const updateBranchDetails = (formData, successCallback) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const currentDate = new Date().toISOString();
    const requestData = {
      payload: {
        ...getState().company.companyDetails,
        branches: [
          {
            ...formData,
            modifiedBy: userDetails.user.profile.userName,
            modifiDate: currentDate
          }
        ]
      }
    };
    createDataWithToken('company/v1/save', userDetails.token, requestData)
      .then((response) => {
        dispatch({ type: LOADING_STOP });
        successCallback(response);
        showSuccessToaster('Branch updated successfully!!!');
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

/**
 * This end point is used to create new branch from manage branches page
 * @param {*} formData
 * @param {*} successCallback
 * @returns
 */
export const createNewBranch = (formData, successCallback) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const currentDate = new Date().toISOString();
    const requestData = {
      payload: {
        ...getState().company.companyDetails,
        branches: [
          {
            ...formData,
            companyId: getState().company.companyDetails.companyId,
            branchId: 0,
            enable: true,
            createdBy: userDetails.user.profile.userName,
            createdDate: currentDate,
            modifiedBy: userDetails.user.profile.userName,
            modifiDate: currentDate
          }
        ]
      }
    };
    createDataWithToken('company/v1/save', userDetails.token, requestData)
      .then((response) => {
        dispatch({ type: LOADING_STOP });
        successCallback(response);
        showSuccessToaster('Branch creation success!!!');
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

/**
 * This is for contact us form in homepage
 *
 * @param {*} formData
 * @param {*} successCallback
 * @returns
 */
export const saveContactUsDetails = (formData, successCallback) => {
  return (dispatch, getState) => {
    const requestData = {
      payload: formData
    };
    createData('app/v1/contact', requestData, {
      catpcha_requestId: getState().app.captcha.requestId,
      catpcha_value: formData.captchaValue
    })
      .then((response) => {
        dispatch({ type: LOADING_STOP });
        successCallback(response);
        showSuccessToaster(
          'Thank you, our representative will contact you soon!'
        );
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

/**
 * TO update workspace branch
 */
export const updateWorkspaceBranch = (branchId, callback) => {
  return (dispatch, getState) => {
    const branches = getState().company.companyDetails.branches;
    dispatch({
      type: UPDATE_WORKSPACE_BRANCH,
      payload: branchId,
      selectedBranch: branches.find((item) => item.branchId === branchId)
    });
    callback();
    showSuccessToaster('Workspace branch updated');
  };
};
