import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Text from '../Text';

const Loading = ({ text }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
      rowGap={3}
      sx={{ height: '100%' }}
    >
      <CircularProgress />
      <Text color="primary">{text || 'Loading...Please Wait !!!'}</Text>
    </Box>
  );
};

export default Loading;
