import AppConstants from '../constants/constants';
// import { showErrorToaster } from "./toaster";

/** *****************************************************************
 * DATABASE CRUD OPERATIONS COMMON METHODS START FROM HERE
 ****************************************************************** */

export const HttpMethodType = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
};

export const logRequest = (url, method, data) => {
  console.log('!!! ==============REQUEST START=============== !!!');
  console.log(
    '[HTTP Request] => URL: ',
    url,
    ' METHOD: ',
    method,
    ' DATA: ',
    data
  );
  console.log('\n', '\n', '\n', '\n');
};

export const logSuccessResponse = (url, method, response) => {
  console.log(
    '!!! ====================SUCCESS RESPONSE=================== !!!'
  );
  console.log(
    '[HTTP Response] => URL: ',
    url,
    ' METHOD: ',
    method,
    ' RESPONSE: ',
    response
  );
  console.log('\n', '\n', '\n', '\n');
};

export const logErrorResponse = (url, method, error) => {
  console.log('!!! ====================ERROR RESPONSE=================== !!!');
  console.log(
    '[HTTP Response] => URL: ',
    url,
    ' METHOD: ',
    method,
    ' RESPONSE: ',
    error
  );
  console.log('\n', '\n', '\n', '\n');
};

export const readNonJSONResponse = (url, token) => {
  return new Promise((resolve, reject) => {
    logRequest(url, HttpMethodType.GET, null);
    let tokenValue = 'Bearer ' + token;
    fetch(AppConstants.baseUrl + url, {
      method: HttpMethodType.GET,
      headers: {
        Authorization: tokenValue
      }
    })
      .then(async function (response) {
        resolve(response);
        logSuccessResponse(url, HttpMethodType.GET, response);
      })
      .catch(async (e) => {
        await requestErrorHandler(url, HttpMethodType.GET, e, reject);
      });
  });
};

export const readData = (url) => {
  return new Promise((resolve, reject) => {
    logRequest(url, HttpMethodType.GET, null);
    fetch(AppConstants.baseUrl + url, {
      method: HttpMethodType.GET,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(async function (response) {
        await successResonseHandler(
          url,
          response,
          HttpMethodType.GET,
          resolve,
          reject
        );
      })
      .catch(async (e) => {
        await requestErrorHandler(url, HttpMethodType.GET, e, reject);
      });
  });
};

export const readDataWithToken = (url, token) => {
  return new Promise((resolve, reject) => {
    logRequest(url, HttpMethodType.GET, null);
    let tokenValue = 'Bearer ' + token;
    fetch(AppConstants.baseUrl + url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: tokenValue
      }
    })
      .then(async function (response) {
        await successResonseHandler(
          url,
          response,
          HttpMethodType.GET,
          resolve,
          reject
        );
      })
      .catch(async (e) => {
        await requestErrorHandler(url, HttpMethodType.GET, e, reject);
      });
  });
};

export const createData = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    logRequest(url, HttpMethodType.POST, data);
    fetch(AppConstants.baseUrl + url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify(data)
    })
      .then(async (response) => {
        await successResonseHandler(
          url,
          response,
          HttpMethodType.POST,
          resolve,
          reject
        );
      })
      .catch(async (e) => {
        await requestErrorHandler(url, HttpMethodType.GET, e, reject);
      });
  });
};

export const createDataWithToken = (url, token, data, headers) => {
  return new Promise((resolve, reject) => {
    logRequest(url, HttpMethodType.POST, data);
    let tokenValue = 'Bearer ' + token;
    fetch(AppConstants.baseUrl + url, {
      method: HttpMethodType.POST,
      headers: {
        'Content-Type': 'application/json',
        Authorization: tokenValue,
        ...headers
      },
      body: JSON.stringify(data)
    })
      .then(async (response) => {
        await successResonseHandler(
          url,
          response,
          HttpMethodType.POST,
          resolve,
          reject
        );
      })
      .catch(async (e) => {
        await requestErrorHandler(url, HttpMethodType.POST, e, reject);
      });
  });
};

export const createDataWithTokenNoContentType = (url, token, data, headers) => {
  return new Promise((resolve, reject) => {
    logRequest(url, HttpMethodType.POST, data);
    let tokenValue = 'Bearer ' + token;
    fetch(AppConstants.baseUrl + url, {
      method: HttpMethodType.POST,
      headers: {
        Authorization: tokenValue,
        ...headers
      },
      body: data
    })
      .then(async (response) => {
        await successResonseHandler(
          url,
          response,
          HttpMethodType.POST,
          resolve,
          reject
        );
      })
      .catch(async (e) => {
        await requestErrorHandler(url, HttpMethodType.POST, e, reject);
      });
  });
};

export const updateDataWithToken = (url, token, data, headers) => {
  return new Promise((resolve, reject) => {
    logRequest(url, HttpMethodType.POST, data);
    let tokenValue = 'Bearer ' + token;
    fetch(AppConstants.baseUrl + url, {
      method: HttpMethodType.PUT,
      headers: {
        'Content-Type': 'application/json',
        Authorization: tokenValue,
        ...headers
      },
      body: JSON.stringify(data)
    })
      .then(async (response) => {
        await successResonseHandler(
          url,
          response,
          HttpMethodType.PUT,
          resolve,
          reject
        );
      })
      .catch(async (e) => {
        await requestErrorHandler(url, HttpMethodType.PUT, e, reject);
      });
  });
};

export const deleteDataWithToken = (url, token) => {
  return new Promise((resolve, reject) => {
    logRequest(url, HttpMethodType.DELETE, {});
    let tokenValue = 'Bearer ' + token;
    fetch(AppConstants.baseUrl + url, {
      method: HttpMethodType.DELETE,
      headers: {
        'Content-Type': 'application/json',
        Authorization: tokenValue
      },
      body: JSON.stringify({})
    })
      .then(async (response) => {
        await successResonseHandler(
          url,
          response,
          HttpMethodType.DELETE,
          resolve,
          reject
        );
      })
      .catch(async (e) => {
        await requestErrorHandler(url, HttpMethodType.GET, e, reject);
      });
  });
};

export const patchDataWithToken = (url, token, data) => {
  return new Promise((resolve, reject) => {
    logRequest(url, HttpMethodType.PATCH, data);
    let tokenValue = 'Bearer ' + token;
    fetch(AppConstants.baseUrl + url, {
      method: HttpMethodType.PATCH,
      headers: {
        'Content-Type': 'application/json',
        Authorization: tokenValue
      },
      body: JSON.stringify(data)
    })
      .then(async (response) => {
        await successResonseHandler(
          url,
          response,
          HttpMethodType.PATCH,
          resolve,
          reject
        );
      })
      .catch(async (e) => {
        await requestErrorHandler(url, HttpMethodType.PATCH, e, reject);
      });
  });
};

const successResonseHandler = async (
  url,
  response,
  method,
  resolve,
  reject
) => {
  if (response.ok) {
    const isJson = response.headers
      .get('content-type')
      ?.includes('application/json');
    let data = {};
    if (isJson && isJson !== undefined) {
      data = await response.json();
    } else {
      data = {
        status: response.status,
        message: response?.errorMessage ? response.errorMessage : ''
      };
    }
    logSuccessResponse(url, method, data);
    resolve(data);
  } else {
    const error = await response.json();
    logErrorResponse(url, method, error.errorMessage, error.status);
    reject(error);
  }
};

const requestErrorHandler = async (url, method, e) => {
  logErrorResponse(url, method, e.message, 'HTTP_ERROR');
};
