export default {
  SUPER_ADMIN: {
    name: 'Super Admin',
    value: 'SUPER_ADMIN',
    color: 'primary',
    actionAllowed: [
      'COMPANY_ADMIN',
      'BRANCH_ADMIN',
      'DOCTOR',
      'STAFF',
      'FINANCE',
      'BRANCH_CROSS_ACCCOUNT'
    ]
  },
  COMPANY_ADMIN: {
    name: 'Company Admin',
    value: 'COMPANY_ADMIN',
    color: 'secondary',
    actionAllowed: [
      'BRANCH_ADMIN',
      'DOCTOR',
      'STAFF',
      'FINANCE',
      'BRANCH_CROSS_ACCCOUNT'
    ]
  },
  BRANCH_ADMIN: {
    name: 'Branch Admin',
    value: 'BRANCH_ADMIN',
    color: 'error',
    actionAllowed: ['DOCTOR', 'STAFF', 'FINANCE', 'BRANCH_CROSS_ACCCOUNT']
  },
  DOCTOR: {
    name: 'Doctor',
    value: 'DOCTOR',
    color: 'success',
    actionAllowed: []
  },
  STAFF: {
    name: 'Staff',
    value: 'STAFF',
    color: 'info',
    actionAllowed: []
  },
  FINANCE: {
    name: 'Finance',
    value: 'FINANCE',
    color: 'black',
    actionAllowed: []
  },
  BRANCH_CROSS_ACCCOUNT: {
    name: 'Cross Branch User',
    value: 'BRANCH_CROSS_ACCCOUNT',
    color: 'info',
    actionAllowed: []
  },
  PHARMACIST: {
    name: 'Pharmacist',
    value: 'PHARMACIST',
    color: 'info',
    actionAllowed: []
  }
};
