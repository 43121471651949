import { UPDATE_WORKSPACE_BRANCH } from '../actions/company';
import { FETCH_ALL_DOCTORS_FOR_BRANCH } from '../actions/doctor';

const initialState = {
  doctors: []
};

const addNewIfNotExisting = (arrayData, doctorsArray) => {
  let arrays = [...arrayData];
  doctorsArray.forEach((item) => {
    const index = arrays.findIndex(
      (doctor) => doctor.profile.userName === item.profile.userName
    );
    if (index === -1) {
      arrays = [...arrays, item];
    }
  });

  return arrays;
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_ALL_DOCTORS_FOR_BRANCH:
      return {
        ...state,
        doctors: addNewIfNotExisting(state.doctors, action.payload)
      };
    case UPDATE_WORKSPACE_BRANCH:
      return initialState;
    default:
      return state;
  }
};
