import { UPDATE_WORKSPACE_BRANCH } from '../actions/company';
import {
  SESSION_STORAGE_TOKEN,
  FETCH_USERS,
  SET_SELECTED_USER,
  UPDATED_USER_PROFILE,
  USER_LOGIN,
  USER_LOGOUT,
  DELETE_COMPANY_USER
} from '../actions/user';

const initialState = {
  users: [],
  selectedUser: null
};

const removeUser = (arrayData, userName) => {
  const arrays = [...arrayData];
  return arrays.filter((item) => item.profile.userName !== userName);
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        userDetails: action.payload
      };
    case UPDATED_USER_PROFILE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          user: action.payload
        }
      };
    case SESSION_STORAGE_TOKEN:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          token: action.payload
        }
      };
    case FETCH_USERS:
      return {
        ...state,
        users: action.payload,
        totalCount: action.totalCount
      };
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload
      };
    case UPDATE_WORKSPACE_BRANCH:
      return {
        ...state,
        users: []
      };
    case DELETE_COMPANY_USER:
      return {
        ...state,
        users: removeUser(state.users, action.payload)
      };
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
