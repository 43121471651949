import {
  BRANCH_SELECTED,
  FETCH_COMPANY_DETAILS,
  UPDATE_WORKSPACE_BRANCH
} from '../actions/company';
import { USER_LOGIN } from '../actions/user';

const initialState = {
  selectedBranch: null,
  companyDetails: null,
  workspaceBranch: null,
  branchDetails: {}
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case BRANCH_SELECTED:
      return {
        ...state,
        selectedBranch: action.payload
      };
    case FETCH_COMPANY_DETAILS:
      return {
        ...state,
        companyDetails: action.payload,
        branchDetails: action.branchDetails
      };
    case USER_LOGIN:
      return {
        ...state,
        workspaceBranch: action.payload.user.profile.branchId
      };
    case UPDATE_WORKSPACE_BRANCH:
      return {
        ...state,
        workspaceBranch: action.payload,
        branchDetails: action.selectedBranch
      };
    default:
      return state;
  }
};
