import { useSelector } from 'react-redux';
import roles from '../constants/roles';

const permissions = {
  'branch.create': [roles.SUPER_ADMIN.value, roles.COMPANY_ADMIN.value],
  'doctor.view': [roles.DOCTOR.value],
  'patient.create': [
    roles.SUPER_ADMIN.value,
    roles.COMPANY_ADMIN.value,
    roles.BRANCH_ADMIN.value,
    roles.DOCTOR.value,
    roles.STAFF.value
  ],
  'pharmacy.create': [
    roles.SUPER_ADMIN.value,
    roles.COMPANY_ADMIN.value,
    roles.PHARMACIST.value
  ],
  'calendar.create': [
    roles.SUPER_ADMIN.value,
    roles.COMPANY_ADMIN.value,
    roles.BRANCH_ADMIN.value
  ],
  'calendar.view': [
    roles.SUPER_ADMIN.value,
    roles.COMPANY_ADMIN.value,
    roles.BRANCH_ADMIN.value,
    roles.STAFF.value
  ],
  'user.create': [
    roles.SUPER_ADMIN.value,
    roles.COMPANY_ADMIN.value,
    roles.BRANCH_ADMIN.value
  ],
  'user.view': [
    roles.SUPER_ADMIN.value,
    roles.COMPANY_ADMIN.value,
    roles.BRANCH_ADMIN.value,
    roles.STAFF.value
  ],
  'user.doctorprofile': [roles.DOCTOR.value],
  'dept.create': [roles.SUPER_ADMIN.value, roles.COMPANY_ADMIN.value]
};

const usePermissions = () => {
  const userRoles = useSelector(
    (state) => state.user.userDetails?.user?.account.authorities
  );
  const hasPermission = (permissionName) => {
    let isActionAllowed = false;
    if (userRoles && userRoles.length) {
      userRoles.every((item) => {
        if (permissions[permissionName].includes(item)) {
          isActionAllowed = true;
          return false;
        }
        return true;
      });
      if (!isActionAllowed) {
        console.log('permission not found');
      }
    }
    return isActionAllowed;
  };

  return { hasPermission };
};

export default usePermissions;
