import { GENERATED_CAPTCHA, LOADING_START, LOADING_STOP } from '../actions/app';

const initialState = {
  loading: false,
  loadingMessage: 'Loading...'
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        loading: true,
        loadingMessage: action.message ? action.message : 'Loading...'
      };
    case LOADING_STOP:
      return {
        ...state,
        loading: false,
        loadingMessage: 'Loading...'
      };
    case GENERATED_CAPTCHA:
      return {
        ...state,
        captcha: action.payload
      };
    default:
      return state;
  }
};
