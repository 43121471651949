import { Box, List, ListItem, ListItemText, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import roles from 'src/constants/roles';
import BranchSelector from './BranchSelector';

const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

const HeaderMenu = () => {
  const userRoles = useSelector(
    (state) => state.user.userDetails.user.account.authorities
  );
  return (
    <>
      <ListWrapper
        sx={{
          display: {
            xs: 'none',
            sm: 'block'
          }
        }}
      >
        <List disablePadding component={Box} display="flex">
          {(userRoles.includes(roles.COMPANY_ADMIN.value) ||
            userRoles.includes(roles.STAFF.value) ||
            userRoles.includes(roles.BRANCH_ADMIN.value)) && (
            <ListItem
              classes={{ root: 'MuiListItem-indicators' }}
              button
              component={NavLink}
              to="/components/managecalendars"
            >
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                primary="Calendar"
              />
            </ListItem>
          )}
          {(userRoles.includes(roles.STAFF.value) ||
            userRoles.includes(roles.COMPANY_ADMIN.value) ||
            userRoles.includes(roles.BRANCH_ADMIN.value)) && (
            <ListItem
              classes={{ root: 'MuiListItem-indicators' }}
              button
              component={NavLink}
              to="/components/patients"
            >
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                primary="Patients"
              />
            </ListItem>
          )}
        </List>
      </ListWrapper>
      {(userRoles.includes(roles.SUPER_ADMIN.value) ||
        userRoles.includes(roles.COMPANY_ADMIN.value) ||
        userRoles.includes(roles.DOCTOR.value)) && (
        <ListWrapper>
          <List disablePadding component={Box} display="flex">
            <ListItem>
              <BranchSelector />
            </ListItem>
          </List>
        </ListWrapper>
      )}
    </>
  );
};

export default HeaderMenu;
