export default {
  BOOKED: {
    name: 'Booked',
    value: 'BOOKED',
    color: 'primary'
  },
  CONFIRMED: {
    name: 'Confirmed',
    value: 'CONFIRMED',
    color: 'green'
  },
  CANCELLED: {
    name: 'Cancelled',
    value: 'CANCELLED',
    color: 'error'
  },
  ATTENDED: {
    name: 'Checked',
    value: 'ATTENDED',
    color: 'warn'
  }
};
