// import { storeToken } from '../../helpers/asyncstorage';
import { createDataWithToken } from '../../helpers/db';
import { httpErrorResponse, LOADING_START, LOADING_STOP } from './app';

export const FETCH_ALL_DOCTORS_FOR_BRANCH = 'FETCH_ALL_DOCTORS_FOR_BRANCH';

export const readAllDoctorsForBranch = (firstName) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const requestData = {
      pageNo: 0,
      pageSize: 5,
      sortBy: 'firstName',
      sortDirection: 'ASC',
      filters: {
        firstName: firstName,
        authorities: 'DOCTOR'
      }
    };

    dispatch({ type: LOADING_START });
    createDataWithToken(
      'users/v1/user/find/matching',
      userDetails.token,
      requestData
    )
      .then((response) => {
        if (response.data.length > 0) {
          dispatch({
            type: FETCH_ALL_DOCTORS_FOR_BRANCH,
            payload: response.data
          });
        }
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const fetchAllDoctorsByBranchId = () => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const requestData = {
      pageNo: 0,
      pageSize: 5,
      sortBy: 'firstName',
      sortDirection: 'ASC',
      filters: {
        authorities: 'DOCTOR',
        branchId: getState().company.workspaceBranch
      }
    };

    dispatch({ type: LOADING_START });
    createDataWithToken(
      'users/v1/user/find/matching',
      userDetails.token,
      requestData
    )
      .then((response) => {
        dispatch({
          type: FETCH_ALL_DOCTORS_FOR_BRANCH,
          payload: response.data
        });
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};
