import { toast } from 'react-toastify';

export const showSuccessToaster = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    shadow: true,
    animation: true,
    hideOnPress: true,
    pauseOnFocusLoss: false,
    delay: 0
  });
};

export const showErrorToaster = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    shadow: true,
    animation: true,
    hideOnPress: true,
    delay: 0,
    pauseOnFocusLoss: false
  });
};

export const showWarningToaster = (message) => {
  toast.warn(message, {
    position: toast.POSITION.TOP_CENTER,
    shadow: true,
    animation: true,
    hideOnPress: true,
    pauseOnFocusLoss: false,
    delay: 0
    // backgroundColor: themeColor.warning500,
  });
};

export const showInfoToaster = (message) => {
  toast.info(message, {
    position: toast.POSITION.TOP_CENTER,
    shadow: true,
    animation: true,
    hideOnPress: true,
    pauseOnFocusLoss: false,
    delay: 0
    // backgroundColor: themeColor.warning500,
  });
};
