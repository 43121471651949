import { UPDATE_WORKSPACE_BRANCH } from '../actions/company';
import {
  ADD_PATIENT,
  PATIENT_HISTORY_ADDED,
  FETCHED_ALL_PATIENT_WITH_TOKEN,
  FETCH_PATIENT_HISTORY,
  PULL_MORE_HISTORY_FOR_PATIENT,
  FETCH_SPECIFIC_TYPE_HISTORY,
  FETCH_PATIENT_BY_PATIENT_ID,
  RESET_PATIENT_HISTORY,
  PATIENT_VITALS_WITH_APPOINTMENT_ID,
  SET_SELECTED_PATIENT
} from '../actions/patient';

const initialState = {
  patients: [],
  patientHistory: {},
  patientTypeHistory: null,
  selectedPatient: null,
  patientsVitals: null
};

const addPatientIfNotAlready = (arrayData, fetchedArray) => {
  let arrays = [...arrayData];
  fetchedArray.forEach((item) => {
    const index = arrays.findIndex(
      (arrayItem) => arrayItem['patientId'] === item.patientId
    );

    if (index === -1) {
      arrays = [...arrays, item];
    }
  });

  return arrays;
};

const addPatientHistoryIfNotAlready = (arrayData, matchingObject) => {
  let arrays = [...arrayData];
  matchingObject.forEach((item) => {
    const index = arrays.findIndex(
      (arrayItem) => arrayItem['patientHistoryId'] === item.patientHistoryId
    );

    if (index === -1) {
      arrays = [...arrays, item];
    }
  });

  return arrays;
};

const addingAtTop = (arrayData, newObject) => {
  let arrays = [...arrayData];
  arrays.unshift(newObject);
  return arrays;
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_PATIENT:
      return {
        ...state,
        patients: [...state.patients, action.payload]
      };
    case FETCHED_ALL_PATIENT_WITH_TOKEN:
      return {
        ...state,
        patients: addPatientIfNotAlready(state.patients, action.payload)
      };
    case FETCH_PATIENT_HISTORY: {
      return {
        ...state,
        patientHistory: action.payload,
        patientTypeHistory: null
      };
    }
    case PULL_MORE_HISTORY_FOR_PATIENT:
      return {
        ...state,
        patientHistory: {
          ...state.patientHistory,
          data: addPatientHistoryIfNotAlready(
            state.patientHistory.data,
            action.payload
          )
        }
      };
    case PATIENT_HISTORY_ADDED:
      return {
        ...state,
        patientHistory: {
          ...state.patientHistory,
          data: addingAtTop(state.patientHistory.data, action.payload)
        }
      };

    case FETCH_SPECIFIC_TYPE_HISTORY:
      return {
        ...state,
        patientTypeHistory:
          action.reportType === state.patientTypeHistory?.reportType &&
          state.patientTypeHistory?.data.length > 0
            ? {
                ...state.patientTypeHistory,
                data: [...state.patientTypeHistory.data, action.payload.data]
              }
            : action.payload
      };
    case RESET_PATIENT_HISTORY:
      return {
        ...state,
        patientTypeHistory: null
      };
    case FETCH_PATIENT_BY_PATIENT_ID:
      return {
        ...state,
        selectedPatient: action.payload
      };
    case PATIENT_VITALS_WITH_APPOINTMENT_ID:
      return {
        ...state,
        patientsVitals: action.payload
      };
    case SET_SELECTED_PATIENT:
      return {
        ...state,
        selectedPatient: action.payload
      };
    case UPDATE_WORKSPACE_BRANCH:
      return initialState;
    default:
      return state;
  }
};
