import {
  ADD_NEW_DEPARTMENT,
  FETCH_ALL_MATCHING_USERS,
  FETCH_COMPANY_DEPARTMENTS,
  SET_SELECTED_DEPARTMENT
} from '../actions/department';

const initialState = {
  departments: [],
  selectedDepts: null,
  users: []
};

const addNewIfNotExisting = (arrayData, userArray) => {
  let arrays = [...arrayData];
  userArray.forEach((item) => {
    const index = arrays.findIndex(
      (user) => user.profile.userName === item.profile.userName
    );
    if (index === -1) {
      arrays = [...arrays, item];
    }
  });

  return arrays;
};

const updateIfExistingOrAddNew = (arrayData, payload) => {
  let arrays = [...arrayData];
  const index = arrays.findIndex(
    (department) => department.departmentId === payload.departmentId
  );

  if (index === -1) {
    arrays = [...arrays, payload];
  } else {
    arrays[index] = payload;
  }

  return arrays;
};

const pushFetchedDepartments = (arrayData, payload) => {
  let arrays = [...arrayData];
  payload.forEach((item) => {
    const index = arrays.findIndex(
      (department) => department.departmentId === item.departmentId
    );

    if (index === -1) {
      arrays = [...arrays, item];
    }
  });
  return arrays;
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SELECTED_DEPARTMENT:
      return {
        ...state,
        selectedDepts: action.payload
      };
    case FETCH_COMPANY_DEPARTMENTS:
      return {
        ...state,
        departments: pushFetchedDepartments(
          state.departments,
          action.payload.data
        ),
        totalDepartments: action.payload.totalCount
      };
    case FETCH_ALL_MATCHING_USERS:
      return {
        ...state,
        users: addNewIfNotExisting(state.users, action.payload)
      };
    case ADD_NEW_DEPARTMENT:
      return {
        ...state,
        departments: updateIfExistingOrAddNew(state.departments, action.payload)
      };
    default:
      return state;
  }
};
