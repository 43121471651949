import { useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  styled,
  Tooltip
} from '@mui/material';

// import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
// import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import * as userActions from '../../../../store/actions/user';
import roles from '../../../../constants/roles';
import salutation from 'src/constants/salutation';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const userDetails = useSelector((state) => state.user.userDetails?.user);
  const profile = userDetails?.profile;
  const account = userDetails?.account;
  const user = {
    name:
      (salutation[profile.title] && salutation[profile.title].name
        ? salutation[profile.title].name
        : '') +
      ' ' +
      profile.firstName +
      ' ' +
      profile.lastName,
    avatar: '/broken-image.jpg',
    jobtitle:
      account.authorities.length > 0
        ? account.authorities
            .map((item) =>
              roles[item] && roles[item] ? roles[item].name : item
            )
            .join(', ')
        : '',
    jobTitleMain:
      account.authorities.length > 0
        ? roles[account.authorities[0]] && roles[account.authorities[0]].name
          ? roles[account.authorities[0]].name
          : account.authorities[0]
        : ''
  };

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [cookies, removeCookie] = useCookies(['TOKEN']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutClickHandler = () => {
    removeCookie('TOKEN');
    console.log(cookies);
    dispatch(
      userActions.logout(() => {
        navigate('/overview', { replace: true });
      })
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuOptionClick = (url) => {
    navigate(url);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={user.jobtitle} arrow placement="bottom">
        <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
          <Avatar
            variant="rounded"
            alt={profile.firstName + ' ' + profile.lastName}
            src={user.avatar}
          />
          <Hidden mdDown>
            <UserBoxText>
              <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
              <UserBoxDescription variant="body2">
                {user.jobTitleMain}
              </UserBoxDescription>
            </UserBoxText>
          </Hidden>
          <Hidden smDown>
            <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
          </Hidden>
        </UserBoxButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          {/* <ListItem
            button
            to="/management/profile/details"
            onClick={() => handleMenuOptionClick('/management/profile/details')}
          >
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary="My Profile" />
          </ListItem> */}
          {/* <ListItem button to="/dashboards/messenger" component={NavLink}>
            <InboxTwoToneIcon fontSize="small" />
            <ListItemText primary="Messenger" />
          </ListItem> */}
          <ListItem
            button
            // to="/management/profile/settings"
            // component={NavLink}
            onClick={() =>
              handleMenuOptionClick('/management/profile/settings')
            }
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="Account Settings" />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={logoutClickHandler}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
